.btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn:hover {
    transition: all 0.3s ease-out;
    color: var(--yellow300);
    /* color: blue; */
    transition: 250ms;
}

.btn--primary {
    background-color: var(--green);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    background-color: var(--green);
    border-radius: 4px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    white-space: nowrap;
    margin-right: 36px;
}

.btn--large {
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
}