:root {
  --yellow: #f7b600;
  --yellow100: #FEF6CB;
  --yellow200: #FEEB98;
  --yellow300: #FCDC65;
  --yellow400: #FACD3E;
  --yellow600: #D49600;
  --yellow700: #B17900;
  --yellow800: #8F5D00;
  --yellow900: #764A00;
  --black: #303649;
  --black200: #CCD6EC;
  --black300: #CCD6EC;
  --black400: #6C7691;
  --white: #fbfbfc;
}

* {
  font-family: 'Frank Ruhl Libre', serif;
  color: var(--black);
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  text-align: center;
}

body {
  background: var(--white);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}