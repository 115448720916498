.serviceinfo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    box-shadow: 0 4px 10px -4px grey;


}

.serviceinfo-container h1,
.serviceinfo-container p,
.serviceinfo-container li {
    color: var(--black200);
}

.serviceinfo-container .serviceinfo {
    display: grid;
    grid-template-areas:
        'goals logo'
        'services logo';
    /* width: 70vw; */
}

.serviceinfo .logo {
    /* height: 35vh; */
    width: 45vw;
    grid-area: logo;
    display: flex;
    align-items: center;
    justify-content: center;
}

.serviceinfo .logo img {
    height: auto;
    width: inherit;
}


.serviceinfo .services {
    padding: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    margin: 24px;
    grid-area: services;
}

.serviceinfo .goals {
    margin: 24px;
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    grid-area: goals;
}

.bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    margin: 24px;
}

.bullets i {
    margin: 0 12px;
    color: var(--yellow);
    cursor: pointer;
}

.bullets i:hover {
    margin: 0 12px;
    color: var(--yellow300);
    transition: 0.5s;
}


/* ANIMATIONS */
.alerts-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alerts-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 1000ms, transform 1000ms;
}

.alerts-exit {
    opacity: 1;
}

.alerts-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 1000ms, transform 1000ms;
}

@media screen and (max-width: 960px) {
    .serviceinfo-container .serviceinfo {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .bullets i {
        font-size: 12px;
    }

    .serviceinfo .logo {
        display: none;
    }
}