.NavbarItems {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    /* margin: 0 50px; */
    background: var(--black200);
    /* box-shadow: 0 4px 10px -4px grey; */
    position: relative;
    background: linear-gradient(90deg, var(--green100) 0%, var(--green200) 100%);
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%); 
    /* border-bottom: var(--white) 1px solid; */
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;
    height: inherit;
}

.navbar-logo img {
    height: inherit;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0px 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
    font-family: 'Mulish', sans-serif;
    text-transform: uppercase;
    /* color: #1b0605; */
    font-weight: 500;
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 14px;
}

.nav-links:hover {
    color: var(--yellow);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: var(--red);
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        margin: 0;
        position: relative;
    }

    .navbar-logo {
        display: none;
    }

    .navbar-logo h1 {
        display: none;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        margin: 0;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: var(--yellow100);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding: 0;
    }

    .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto;
    }

    .nav-links {
        text-align: center;
        color: var(--black);
        padding: 2rem 0;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: var(--red);
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto auto 22px auto;
        border-radius: 4px;
        width: 80%;
        background: #a9a6d5;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #6568F4;
        transition: 250ms;
    }

    Button {
        display: none;
    }
}

@media screen and (max-width: 320px) {
    .navbar-logo {
        position: absolute;
        top: 0;
        margin-left: 0;
        left: 0;
        transform: translate(0%, 50%);
    }
}