.home-container {
    display: flex;
    width: 100%;
    background: linear-gradient(0deg, var(--white) 0%, var(--yellow100) 100%);
}

.home-container .desc-container {
    width: inherit;
    height: 80vh;
    padding: 0 100px;
}

.desc-container .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 40px;
    /* background-color: red; */
    height: 45vh;
}

.logo img {
    height: 100%;
}

.desc-container .description {
    padding: 8px 20px;
}

.desc-container .description p {
    font-size: 18px;
    text-align: justify;
}

.home-container .background-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-container .background {
    height: 100vh;
}

.background-container .background img {
    height: 100%;
    transform: rotateY(180deg);
}

.legality-container {
    margin: 40px 12px;
}

.legality-container .legality {
    margin: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    /* flex-direction: column; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    transition: all 0.2s linear;
    border-radius: 8px;

}

.legality-container .legality .pic {
    height: 60vh;
}

.legality-container .legality h3 {
    text-align: center;
}

.legality-container .legality img {
    height: 100%;
}

/* .legality-container .legality:hover {
    transform: scale(1.1);
} */

.legality-container .legality li:before {
    /* padding-right: 5px; */
}

.legality-container .legality ul {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: auto;
    color: var(--boldBlue);
    margin-left: 0;
    padding-left: 1em;
    font-size: 16px;
    text-align: just;
}

.legality-container .legality li {}

.bannerbox {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(180deg, var(--white) 0%, var(--backgroundShadow) 100%);
    border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%); */
}

.bannerbox .grid-container {
    width: 80rem;
}

.bannerbox .grid-container .grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.bannerbox .grid-container .grid .card, .card-nohover {
    box-shadow: rgb(98 107 121 / 15%) 0px 20px 50px;
    border-radius: 8px;
    width: auto;
    margin: 20px;
    padding: 25px;
}

.bannerbox .grid-container .grid .card-nohover {
    grid-row: 1;
    grid-column: 1/4;
}

.bannerbox .grid-container .grid .card:hover {
    background: var(--black200);
    transition: all 0.2s ease-out;
}

.bannerbox .grid-container .social-media {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 20px;
    justify-content: space-around;
}

.bannerbox .grid-container .social-media .fa {
    padding: 13px;
    font-size: 20px;
    width: 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    color: var(--white);
    background: var(--red);
}

/* Add a hover effect if you want */

.bannerbox .grid-container .social-media .fa:hover {
    opacity: 0.7;
}


.service-container {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    background: linear-gradient(180deg, var(--white) 0%, var(--yellow100) 100%);
    margin-top: 24px;
}

.service-container .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 40px;
    /* background-color: red; */
    height: 45vh;
}

.service-container .desc-container {
    /* width: inherit; */
    height: 80vh;
    padding: 0 100px;
}



.logo img {
    height: 100%;
}

.desc-container .description {
    padding: 8px 20px;
}

.desc-container .description p {
    font-size: 18px;
    text-align: justify;
}

.service-container .background-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-container .background {
    height: 100vh;
}

.background-container .background img {
    height: 100%;
    transform: rotateY(180deg);
}

@media screen and (max-width: 960px) {
    .home-container .desc-container {
        height: auto;
        padding: 0;
    }

    .home-container .background-container {
        display: none;
    }

    .legality-container .legality .pic {
        display: none;
    }

    .legality-container .legality {
        margin: 0;
    }

    .bannerbox .grid-container .grid {
        display: grid;
        grid-template-columns: auto;
    }

    .bannerbox .grid-container .grid .card-nohover {
        grid-column: 1;
    }

    .service-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .service-container .desc-container {
        height: auto;
        padding: 0;
    }

    .service-container .desc-container p{
        text-align: center;
    }

    .service-container .desc-container ul{
        /* padding: 12px; */
    }
}