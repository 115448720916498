.footer-container {
    margin-top: 20px;
    padding: 15px 0;
    font-size: 1.2rem;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    background: linear-gradient(90deg, var(--green100) 0%, var(--green200) 100%);
}

h3, i, p, p.desc, a{
    color: var(--black)
}

.footer-wrap {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin: 10px 24px;
    padding: 0 40px;
}

.footer-wrap i {
    width: 20px;
    text-align: center;
}
.footer-wrap h3{
    font-size: 20px;
}
.footer-wrap p {
    margin: 12px 24px;
    font-size: 12px;
}
.footer-wrap p.desc {
    margin: 0;
    font-size: 16px;
}
.footer-cell {
    display: flex;
}

.footer-cell .information .row {
    display: flex;
    align-items: center;
}
.footer-cell .information .row p, .footer-cell .information .row a, 
.footer-cell .information .row a:active{
    margin: 12px 24px;
    font-size: 12px;
    text-decoration: none;
}
.footer-cell .information .row a{
    cursor: pointer;
}
.footer-cell .information .row a:hover{
    color: var(--yellow);
    transition: all 0.2s ease-out;
}

.footer-cell .information {
    width: 100%;
}

.footer-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: bold;
    /* padding: 12px 12px 12px 12px; */
    width: 100%;
    height: 100%;

}

.footer-logo img {
    height: auto;
    width: 300px;
}

@media screen and (max-width: 960px) {
    .footer-wrap {
        display: flex;
        flex-direction: column;
    }
    .footer-logo img {
        height: auto;
        width: 80vw;
    }
}