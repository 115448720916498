.client-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-container .client-preview {
    display: grid;
    width: 70vw;
    grid-template-columns: 40% 60%;
    height: 360px;
}

.client-preview .client-preview-pic {
    border-right: 1px solid var(--green);
    padding: 36px;
    display: grid;
    grid-template-rows: 70% 30%;
    justify-content: center;
    align-items: center;
}

.client-preview .client-preview-pic img {
    width: 100%;
    height: auto;
}

.client-preview .client-preview-pic p {
    font-size: 17px;
    text-align: center;
    /* color: var(--boldBlue); */
    margin: 0;
    white-space: break-spaces;
}

.client-preview .client-preview-desc {
    padding: 36px;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.client-preview .client-preview-desc p {}

@media screen and (max-width: 960px) {
    .client-container .client-preview {
        display: grid;
        width: auto;
        grid-template-columns: auto;
        height: auto;
        /* min-height: 500px; */
    }
}